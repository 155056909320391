import React from "react"
import { graphql } from "gatsby"
import moment from "moment"
import Layout from "../../components/layout"
import Markdown from "react-markdown"
import { get } from "lodash"
import { withPrefix } from "../../utils"
import { PageBreadcrumb } from "../../components/shared"
import { BlogSidebar } from "../../components/blogs"
import { GatsbyImage } from "gatsby-plugin-image"

import { Container, Row, Col, Card, CardBody } from "reactstrap"

export const query = graphql`
  query BlogQuery($slug: String!) {
    strapiGlobal {
      siteName
      favicon {
        localFile {
          publicURL
        }
      }
      defaultSeo {
        robots
        metaDescription
        metaTitle
        keywords
        shareImage {
          localFile {
            publicURL
          }
        }
      }
    }
    categoryCounts: allStrapiBlog {
      group(field: category___name) {
        totalCount
        fieldValue
      }
    }
    recentBlogs: allStrapiBlog(
      limit: 3
      sort: { fields: postedOn, order: DESC }
    ) {
      edges {
        node {
          slug
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 80, height: 60)
              }
            }
          }
          title
          postedOn
        }
      }
    }
    strapiBlog(slug: { eq: $slug }) {
      strapiId
      title
      description
      content
      postedOn
      schemaJSON {
        interactionStatistic {
          _type
          interactionService {
            _type
            name
            url
          }
          interactionType
          userInteractionCount
        }
        _type
        _context
      }
      image {
        localFile {
          publicURL
          childImageSharp {
            gatsbyImageData
            fixed {
              src
            }
          }
        }
      }
      author {
        name
        picture {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData
              fixed(width: 60, height: 60) {
                src
              }
            }
          }
        }
      }
    }
  }
`

const Blog = ({ data }) => {
  const { strapiBlog, strapiGlobal, categoryCounts, recentBlogs } = data

  const seo = {
    metaTitle: strapiBlog.title,
    metaDescription: strapiBlog.description,
    shareImage: strapiBlog.image,
    contentType: "Article",
    author: strapiBlog.author.name,
    schemaJSON: strapiBlog.schemaJSON,
  }

  let content = get(strapiBlog, "content", null)
  content = content.replace(
    /\(\/uploads\//g,
    "(https://cms.bettercapital.us/uploads/"
  )

  return (
    <Layout seo={seo} strapiGlobal={strapiGlobal}>
      <PageBreadcrumb
        page={{
          source: "blog",
          showBreadcrumb: true,
        }}
      >
        <h2>{strapiBlog.title}</h2>
        <ul className="list-unstyled mt-4 text-white">
          <li className="h6 user mr-2">
            <i className="mdi mdi-account"></i> {strapiBlog.author.name}
          </li>
          <li className="h6 date ">
            <i className="mdi mdi-calendar-check"></i>{" "}
            {moment(strapiBlog.postedOn).format("MMM Do YYYY")}
          </li>
        </ul>
      </PageBreadcrumb>
      <div className="position-relative">
        <div className="shape overflow-hidden text-white">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>

      <section className="section">
        <Container>
          <Row>
            <Col lg="8" md="6">
              <Card className="blog blog-detail border-0 shadow rounded">
                <GatsbyImage
                  image={
                    strapiBlog.image.localFile.childImageSharp.gatsbyImageData
                  }
                  className="img-fluid rounded-top"
                  alt={strapiBlog.title + " banner"}
                />
                <CardBody className="content">
                  <Markdown source={content} escapeHtml={false} />
                </CardBody>
              </Card>

              {/* comments */}
              {/* <CommentsBox
                comments={[
                  {
                    id: 1,
                    image: client1,
                    name: "Lorenzo Peterson",
                    date: "15th August, 2019",
                    time: "01:25 pm",
                    desc:
                      "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour",
                  },
                ]}
              /> */}
            </Col>

            {/* sidebar */}
            <Col lg={4} xs={12} md={6} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
              <BlogSidebar
                categoryCounts={categoryCounts}
                recentBlogs={recentBlogs}
              />
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  )
}

export default Blog
